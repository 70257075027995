import React, { FC } from 'react';
import styled from "styled-components";
import { theme } from "../../theme";
import Heading from "../../components/elements/Heading";
import Description from "../../components/elements/Description";
interface SideBarMenuProps {
  item?: any;
}

const ServiceDetailSection = styled.section`
  text-align: center;
  padding: ${props => props.theme.spacing[3]}rem 1rem;
  background: ${(props: any) => (props.theme.colors.bgmain)} ;
`;

const ContactInfo = styled.div`
  a {
    padding: ${props => props.theme.spacing[0]}rem
      ${props => props.theme.spacing[1]}rem;
    margin: ${props => props.theme.spacing[1]}rem 0;
  }
`;

const WrapperList = styled.ul`
    margin-top: 2rem;
    padding: 0; 
`;

const WrapperListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  text-align: left;
  &:last-child {
    span::after {
      display: none;
    }
  }
`;

const WrapperListIcon = styled.span`
    height: 50px;
    width: 50px;
    flex-shrink:0;
    border: 1px dashed  ${(props: any) => (props.theme.colors.lightGrey)};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: ${(props: any) => (props.theme.colors.white)} ;
    &::after {
      content: "";
      position: absolute;
      border: 1px dashed ${(props: any) => (props.theme.colors.brown)} ;
      height: 33px;
      top: 48px;
      bottom: 0;
    }
`;

const WrapperListItemName = styled.span`
  padding-left: 1rem;
  font-family: ${(props: any) => (props.theme.font.families.poppins)};
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color:  ${(props: any) => (props.theme.colors.black)};
`;

const SideBarMenu: FC<SideBarMenuProps> = ({ item }) => {

  return (
    <ServiceDetailSection >

      <ContactInfo>
        <Heading margin="0" textAlign={"center"} textFontWeight={theme.fontWeight.bold} boldFontWeight={theme.fontWeight.medium} fontSize={'1.5rem'} textColor={theme.text.primary} text={"Proudly servicing:"} />
        <Description textColor={theme.colors.mediumGray} margin={"0"} text={"the following areas in Georgia"} />
        <WrapperList>
          <WrapperListItem>
            <WrapperListIcon >
              <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46381 0C3.79684 0 0 3.45238 0 7.69591C0 12.9623 7.57428 20.6936 7.89676 21.0201C8.19966 21.3269 8.7285 21.3263 9.03085 21.0201C9.35334 20.6936 16.9276 12.9623 16.9276 7.69591C16.9275 3.45238 13.1307 0 8.46381 0ZM8.46381 11.5679C6.11574 11.5679 4.2055 9.83096 4.2055 7.69591C4.2055 5.56086 6.11578 3.82393 8.46381 3.82393C10.8118 3.82393 12.7221 5.56091 12.7221 7.69595C12.7221 9.831 10.8118 11.5679 8.46381 11.5679Z" fill="#FF8A00" />
              </svg>
            </WrapperListIcon>
            <WrapperListItemName>Alpharetta</WrapperListItemName>
          </WrapperListItem>
          <WrapperListItem>
            <WrapperListIcon >
              <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46381 0C3.79684 0 0 3.45238 0 7.69591C0 12.9623 7.57428 20.6936 7.89676 21.0201C8.19966 21.3269 8.7285 21.3263 9.03085 21.0201C9.35334 20.6936 16.9276 12.9623 16.9276 7.69591C16.9275 3.45238 13.1307 0 8.46381 0ZM8.46381 11.5679C6.11574 11.5679 4.2055 9.83096 4.2055 7.69591C4.2055 5.56086 6.11578 3.82393 8.46381 3.82393C10.8118 3.82393 12.7221 5.56091 12.7221 7.69595C12.7221 9.831 10.8118 11.5679 8.46381 11.5679Z" fill="#FF8A00" />
              </svg>
            </WrapperListIcon>
            <WrapperListItemName>Canton</WrapperListItemName>
          </WrapperListItem>
          <WrapperListItem>
            <WrapperListIcon >
              <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46381 0C3.79684 0 0 3.45238 0 7.69591C0 12.9623 7.57428 20.6936 7.89676 21.0201C8.19966 21.3269 8.7285 21.3263 9.03085 21.0201C9.35334 20.6936 16.9276 12.9623 16.9276 7.69591C16.9275 3.45238 13.1307 0 8.46381 0ZM8.46381 11.5679C6.11574 11.5679 4.2055 9.83096 4.2055 7.69591C4.2055 5.56086 6.11578 3.82393 8.46381 3.82393C10.8118 3.82393 12.7221 5.56091 12.7221 7.69595C12.7221 9.831 10.8118 11.5679 8.46381 11.5679Z" fill="#FF8A00" />
              </svg>
            </WrapperListIcon>
            <WrapperListItemName>East Cobb/Marietta</WrapperListItemName>
          </WrapperListItem>
          <WrapperListItem>
            <WrapperListIcon >
              <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46381 0C3.79684 0 0 3.45238 0 7.69591C0 12.9623 7.57428 20.6936 7.89676 21.0201C8.19966 21.3269 8.7285 21.3263 9.03085 21.0201C9.35334 20.6936 16.9276 12.9623 16.9276 7.69591C16.9275 3.45238 13.1307 0 8.46381 0ZM8.46381 11.5679C6.11574 11.5679 4.2055 9.83096 4.2055 7.69591C4.2055 5.56086 6.11578 3.82393 8.46381 3.82393C10.8118 3.82393 12.7221 5.56091 12.7221 7.69595C12.7221 9.831 10.8118 11.5679 8.46381 11.5679Z" fill="#FF8A00" />
              </svg>
            </WrapperListIcon>
            <WrapperListItemName>Milton</WrapperListItemName>
          </WrapperListItem>
          <WrapperListItem>
            <WrapperListIcon >
              <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46381 0C3.79684 0 0 3.45238 0 7.69591C0 12.9623 7.57428 20.6936 7.89676 21.0201C8.19966 21.3269 8.7285 21.3263 9.03085 21.0201C9.35334 20.6936 16.9276 12.9623 16.9276 7.69591C16.9275 3.45238 13.1307 0 8.46381 0ZM8.46381 11.5679C6.11574 11.5679 4.2055 9.83096 4.2055 7.69591C4.2055 5.56086 6.11578 3.82393 8.46381 3.82393C10.8118 3.82393 12.7221 5.56091 12.7221 7.69595C12.7221 9.831 10.8118 11.5679 8.46381 11.5679Z" fill="#FF8A00" />
              </svg>
            </WrapperListIcon>
            <WrapperListItemName>Roswell</WrapperListItemName>
          </WrapperListItem>
          <WrapperListItem>
            <WrapperListIcon >
              <svg width="17" height="22" viewBox="0 0 17 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.46381 0C3.79684 0 0 3.45238 0 7.69591C0 12.9623 7.57428 20.6936 7.89676 21.0201C8.19966 21.3269 8.7285 21.3263 9.03085 21.0201C9.35334 20.6936 16.9276 12.9623 16.9276 7.69591C16.9275 3.45238 13.1307 0 8.46381 0ZM8.46381 11.5679C6.11574 11.5679 4.2055 9.83096 4.2055 7.69591C4.2055 5.56086 6.11578 3.82393 8.46381 3.82393C10.8118 3.82393 12.7221 5.56091 12.7221 7.69595C12.7221 9.831 10.8118 11.5679 8.46381 11.5679Z" fill="#FF8A00" />
              </svg>
            </WrapperListIcon>
            <WrapperListItemName>Woodstock</WrapperListItemName>
          </WrapperListItem>
        </WrapperList>
      </ContactInfo>

    </ServiceDetailSection>
  );
};

export default SideBarMenu;